; (function ($, document, window, undefined) {
    var pluginName = 'BookASeat',
         defaults = {};
    
    function BookASeat(element, options) {
        this.element = $(element);
        this.header = $('#header');
        this.options = $.extend({}, defaults, options);
        this.init();
    }

    BookASeat.prototype.init = function() {
        this.addUIInteractions();
    };

    BookASeat.prototype.addUIInteractions = function() {
        var self = this;

        this.element.click(function(e) {
            e.preventDefault();
        });
            
    };

    BookASeat.prototype.transitionPage = function(pageLink) {
        window.location.href = pageLink;
    };

    $.fn[pluginName] = function (options) {
        return this.each(function () {
            if (!$.data(this, 'plugin_' + pluginName)) {
                $.data(this, 'plugin_' + pluginName, new BookASeat(this, options));
            }
        });
    };
})(jQuery, document, window, undefined);